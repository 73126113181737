<template>
    <div class="card p-3 dashboard d-flex flex-column"
         style="height: calc(100dvh - 6rem);">

    </div>
</template>

<script>
    export default {

    }
</script>